.boi{
    width: 400px;
    height: 499px;
    background-color: red;
}
.owl-prev{
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
height: 0px;
    position: absolute;
    top: 37%;
    z-index: 44455555;
    left: 0px;
}

.owl-next{
    height: 0px;
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    position: absolute;
    top: 37%;
    z-index: 44455555;
    right: 0px;
}
.productowl .owl-prev span {
    padding: 0px 9px;
        background: rgba(255, 255, 255, 0.5);
    margin: 0px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #eee;
    /* height: 18px; */
}
.productowl .owl-next span {
    border: 1px solid #eee;
    padding: 0px 9px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        overflow: hidden;
    /* height: 18px; */

    margin: 0px;
}
.owl-next span:hover{
    background-color: green;
}
.owl-nav{
    visibility: hidden;
}
.owl-carousel:hover > .owl-nav{
  visibility: visible;
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    height: 100%;
}
.owl-prev span:hover{
    background-color: green;
}
.owl-next span{
    font-size: 21px;
}
.owl-prev span{
    font-size: 21px;
}
.owl-next:hover > span{
    color: rgb(237, 244, 237);
}
.owl-prev:hover > span{
    color: rgb(234, 241, 234);
}
.positionre {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 34px;
    position: relative;
}

@media screen and (max-width: 552px) {
    .positionre {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 5px;
        position: relative;
    }
}