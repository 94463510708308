
.positionre .owl-prev{
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
height: 0px;
    position: absolute;
    top: 40%;
    z-index: 44455555;
    left: 28px;
}

.positionre .owl-next{
    height: 0px;
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    position: absolute;
    top: 40%;
    z-index: 44455555;
    right: 28px;
}
.positionre .owl-next span {
    font-size: 31px;
    padding: 0px 18px;
        background: rgba(255, 255, 255, 0.5);
    margin: 0px;
}
.positionre .owl-next span:hover{
    background-color: #fff;
}
.positionre .owl-nav{
    visibility: hidden;
}
.positionre .owl-carousel:hover > .owl-nav{
  visibility: visible;
}
.positionre .owl-prev span {
    font-size: 31px;
    padding: 0px 18px;
        background: rgba(255, 255, 255, 0.5);
    margin: 0px;
}
.positionre .owl-prev span:hover{
    background-color: #fff;
}
.positionre .owl-next span{
    font-size: 31px;
}
.positionre .owl-prev span{
    font-size: 31px;
}
.positionre .owl-next:hover > span{
    color: green;
}
.positionre .owl-prev:hover > span{
    color: green;
}
.positionre {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
    position: relative;
}

.featuredcomps .owl-prev{
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
height: 0px;
    position: absolute;
    top: 40%;
    z-index: 44455555;
    left: 14px;
}

.featuredcomps .owl-next{
    height: 0px;
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    position: absolute;
    top: 40%;
    z-index: 44455555;
    right: 14px;
}
.featuredcomps .owl-next span {
    font-size: 31px;
    padding: 5px 22px;
        background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
    margin: 0px;
}
.featuredcomps .owl-next span:hover{
    background-color: #fff;
}
.featuredcomps .owl-nav{
    visibility: hidden;
}
.featuredcomps .owl-carousel:hover > .owl-nav{
  visibility: visible;
}
.featuredcomps .owl-prev span {
    font-size: 31px;
    padding: 5px 22px;
        background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
    margin: 0px;
}
.featuredcomps .owl-prev span:hover{
    background-color: #fff;
}
.featuredcomps .owl-next span{
    font-size: 31px;
}
.featuredcomps .owl-prev span{
    font-size: 31px;
}
.featuredcomps .owl-next:hover > span{
    color: green;
}
.featuredcomps .owl-prev:hover > span{
    color: green;
}
.careofyoucomps .owl-prev{
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
height: 0px;
    position: absolute;
    top: 40%;
    z-index: 44455555;
    left: 14px;
}

.careofyoucomps .owl-next{
    height: 0px;
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    position: absolute;
    top: 40%;
    z-index: 44455555;
    right: 14px;
}
.careofyoucomps .owl-next span {
    font-size: 31px;
    padding: 5px 22px;
        background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
    margin: 0px;
}
.outercate .owl-next span {
    font-size: 31px;
    padding: 0px 17px;
        background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
    margin: 0px;
    position: relative;
    top: -18px;
}
.testimonalsir .owl-next span {
    font-size: 31px;
    padding: 0px 17px;
        background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
    margin: 0px;
    position: relative;
    top: -18px;
}
.careofyoucomps .owl-next span:hover{
    background-color: #fff;
}
.careofyoucomps .owl-nav{
    visibility: hidden;
}
.careofyoucomps .owl-carousel:hover > .owl-nav{
  visibility: visible;
}
.careofyoucomps .owl-prev span {
    font-size: 31px;
    padding: 5px 22px;
        background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
    margin: 0px;
}
.outercate .owl-prev span {
    font-size: 31px;
    padding: 0px 17px;
        background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
    margin: 0px;
    position: relative;
    top: -18px;
}
.testimonalsir .owl-prev span {
    font-size: 31px;
    padding: 0px 17px;
        background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
    margin: 0px;
    position: relative;
    top: -18px;
}
.careofyoucomps .owl-prev span:hover{
    background-color: #fff;
}
.careofyoucomps .owl-next span{
    font-size: 31px;
}
.careofyoucomps .owl-prev span{
    font-size: 31px;
}
.careofyoucomps .owl-next:hover > span{
    color: green;
}
.careofyoucomps .owl-prev:hover > span{
    color: green;
}
.featuredcomps {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 31px;
    position: relative;
}

@media screen and (max-width: 768px) {
    .featuredcomps {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 18px;
        position: relative;
    }
    .paddingttor {
        padding: 17px 13px;
        /* width: 33%; */
    }
}
.comppara h4 {
    font-size: 14px;
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 5px;
    font-family: "Poppins", sans-serif;
    color: #727271;
    text-align: center;
}
.addtocarthere button {
    width: 67%;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 5px;
    font-size: 13px;
    text-transform: uppercase;
    background-color: #66832A;
    color: white;
    font-weight: 600;
}
@media screen and (max-width: 592px) {
    .addtocarthere button {
        width: 70%;
        border-radius: 8px;
        border: none;
        outline: none;
        padding: 5px;
        font-size: 11px;
        text-transform: uppercase;
        background-color: #66832A;
        color: white;
        font-weight: 600;

    }
    .careofyoucomps .owl-next span {
        font-size: 31px;
        padding: 0px 17px;
            background: rgba(255, 255, 255, 0.5);
                box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
        margin: 0px;
    }
    .careofyoucomps .owl-prev span {
        font-size: 31px;
        padding: 0px 17px;
            background: rgba(255, 255, 255, 0.5);
                box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
        margin: 0px;
    }
    .compdetails {
        padding: 10px 6px;
        background-color: white;
    }
}
@media screen and (max-width: 552px) {
    .resizebox > .paddingttor {
        padding: 17px 10px;
        width: 49%;
    }
    .featuredcompsagain {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 6px;
        position: relative;
    }

}
@media screen and (max-width: 552px) {
    .positionre .owl-prev{
      background: none;
      color: inherit;
      border: none;
      padding: 0 !important;
      font: inherit;
  height: 0px;
      position: absolute;
      top: 40%;
      z-index: 44455555;
      left: -3px;
  }
  
  .positionre .owl-next{
      height: 0px;
      background: none;
      color: inherit;
      border: none;
      padding: 0 !important;
      font: inherit;
      position: absolute;
      top: 40%;
      z-index: 44455555;
      right: -3px;
  }
}